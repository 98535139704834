import { FC, useEffect, useState } from "react";
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Accordion,
  AccordionDetails,
  Typography,
  AccordionSummary,
  CircularProgress,
  Box,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { loadCredits, loadHypotheticalCredits } from "../services/interhyp";
import {
  setCredits,
  setHypotheticalCredits,
  setPropertyPrice,
} from "../store/finances";
import { useAppDispatch, useAppSelector } from "../hooks/redux";

export const CreditOffers: FC = () => {
  const { propertyPrice, credits, hypotheticalCredits, years, reloadRequired } =
    useAppSelector((state) => state.finances);
  const dispatch = useAppDispatch();

  const [isDirty, setIsDirty] = useState(false);
  const [percentageBroker, setPercentageBroker] = useState(1.78);
  const [percentageNotary, setPercentageNotary] = useState(2.0);
  const [percentageTax, setPercentageTax] = useState(3.5);

  const [isLoading, setIsLoading] = useState(false);

  async function reloadCredits() {
    setIsDirty(false);
    setIsLoading(true);
    const newCredits = await loadCredits(
      years,
      percentageBroker,
      percentageTax,
      percentageNotary
    );
    const hypotheticalCredits = await loadHypotheticalCredits(
      years,
      percentageBroker,
      percentageTax,
      percentageNotary
    );
    dispatch(setHypotheticalCredits(hypotheticalCredits.map((c) => c[0])));
    dispatch(setCredits(newCredits.map((c) => c[0])));
    setIsLoading(false);
  }

  useEffect(() => {
    setIsDirty(true);
  }, [percentageTax, percentageBroker, percentageNotary]);

  return (
    <div>
      <h3
        style={{
          color: "darkgreen",
          borderBottom: "1px solid darkgreen",
          paddingBottom: "6px",
        }}
      >
        Kreditangebote - via Interhyp
      </h3>
      <Grid container direction="column" alignItems="center">
        {(reloadRequired || isDirty) && (
          <Grid
            sx={{
              width: "75%",
              "@media (max-width: 720px)": {
                width: "100%",
              },
              textAlign: "center",
              color: "white",
              fontWeight: "bold",
              backgroundColor: "orangered",
              borderRadius: "4px",
              margin: "5px 0",
              padding: "10px",
            }}
          >
            Bitte Angebote neu Laden, da sich die Parameter geändert haben.
          </Grid>
        )}
        <Grid item>
          <p>
            Nun geht es darum herauszufinden bei welchem Eigenkapital aktuell
            welche effektiven Zinsen angeboten würden. Diese Angaben beziehen
            wir von{" "}
            <a
              href="https://baufi.interhyp.de/finanzierung-anfragen-a"
              style={{
                textDecoration: "none",
                fontWeight: "bold",
                color: "darkgreen",
              }}
            >
              Interhyp
            </a>{" "}
            und sind natürlich von vielen Einzelfaktoren abhängig. Es soll hier
            nur eine grobe Vorstellung der Entwicklung vermittelt werden.
            Zusätzlich wird hier der Preisindex miteinbezogen und darüber der
            Kaufpreis für die kommenden Jahre entsprechend angepasst. Diese
            Werte sind in der Tabelle{" "}
            <i>bei hypothetischer Immobilienpreisanpassung</i> mit
            berücksichtigt.
          </p>
          <p>
            Nicht mit einbezogen ist eine hypothetische Veränderung des Leitzins
            in den kommenden Jahren.
          </p>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        sx={{
          marginBottom: "12px",
        }}
      >
        <Grid item md={2} xs={6}>
          <TextField
            sx={{
              width: "100%",
            }}
            variant="outlined"
            label="Immobilienpreis"
            margin="normal"
            name="property"
            type="number"
            value={propertyPrice}
            onChange={(e) =>
              dispatch(setPropertyPrice(parseFloat(e.target.value)))
            }
          />
        </Grid>
        <Grid item md={2} xs={6}>
          <TextField
            sx={{
              width: "100%",
            }}
            variant="outlined"
            label="Anteil Notar"
            margin="normal"
            name="notary"
            type="number"
            value={percentageNotary}
            onChange={(e) => setPercentageNotary(parseFloat(e.target.value))}
          />
        </Grid>
        <Grid item md={2} xs={6}>
          <TextField
            sx={{
              width: "100%",
            }}
            variant="outlined"
            label="Anteil Makler"
            margin="normal"
            name="broker"
            type="number"
            value={percentageBroker}
            onChange={(e) => setPercentageBroker(parseFloat(e.target.value))}
          />
        </Grid>
        <Grid item md={2} xs={6}>
          <TextField
            sx={{
              width: "100%",
            }}
            variant="outlined"
            label="Anteil Grunderwerbssteuer"
            margin="normal"
            name="tax"
            type="number"
            value={percentageTax}
            onChange={(e) => setPercentageTax(parseFloat(e.target.value))}
          />
        </Grid>
        <Grid
          item
          md={2}
          xs={12}
          justifySelf="right"
          sx={{ textAlign: "right", height: "100%" }}
          alignSelf="center"
        >
          <Button
            color="warning"
            sx={{ padding: "12px" }}
            variant="outlined"
            endIcon={<RefreshIcon />}
            onClick={(_event) => reloadCredits()}
          >
            Aktualisieren
          </Button>
        </Grid>
      </Grid>
      {isLoading && (
        <Box sx={{ margin: "12px 0", textAlign: "center" }}>
          <CircularProgress color="success" />
        </Box>
      )}
      {credits.length > 0 && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography
              sx={{ width: "35%", flexShrink: 0, wordWrap: "break-word" }}
            >
              Kreditangebote
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              Ohne hypothetische Immobilienpreisanpassung
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Jahr</TableCell>
                    <TableCell>Bank</TableCell>
                    <TableCell align="right">Sollzins</TableCell>
                    <TableCell align="right">Effektiver Zins</TableCell>
                    <TableCell align="right">Monatliche Rate</TableCell>
                    <TableCell align="right">Gesamtkredit</TableCell>
                    <TableCell align="right">Restzahlung</TableCell>
                    <TableCell align="right">Dauer</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {credits?.map((credit, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {years[index].year}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {credit.bank}
                      </TableCell>
                      <TableCell align="right">
                        {credit.nominal.toFixed(2)} %
                      </TableCell>
                      <TableCell align="right">
                        {credit.effective.toFixed(2)} %
                      </TableCell>
                      <TableCell align="right">
                        {credit.monthlyPayment.toFixed(2)} €
                      </TableCell>
                      <TableCell align="right">
                        {credit.loanAmount.toFixed(2)} €
                      </TableCell>
                      <TableCell align="right">
                        {(credit.loanAmount - credit.totalPayment).toFixed(2)} €
                      </TableCell>
                      <TableCell align="right">
                        {credit.totalDuration.years} Jahre und{" "}
                        {credit.totalDuration.months} Monate
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      )}
      {hypotheticalCredits.length > 0 && (
        <Accordion
          sx={{
            marginTop: "10px",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography
              sx={{ width: "35%", flexShrink: 0, wordWrap: "break-word" }}
            >
              Kreditangebote
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              bei hypothetische Immobilienpreisanpassung
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Jahr</TableCell>
                    <TableCell>Bank</TableCell>
                    <TableCell align="right">Sollzins</TableCell>
                    <TableCell align="right">Effektiver Zins</TableCell>
                    <TableCell align="right">Monatliche Rate</TableCell>
                    <TableCell align="right">Gesamtkredit</TableCell>
                    <TableCell align="right">Restzahlung</TableCell>
                    <TableCell align="right">Dauer</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {hypotheticalCredits?.map((credit, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {years[index].year}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {credit.bank}
                      </TableCell>
                      <TableCell align="right">
                        {credit.nominal.toFixed(2)} %
                      </TableCell>
                      <TableCell align="right">
                        {credit.effective.toFixed(2)} %
                      </TableCell>
                      <TableCell align="right">
                        {credit.monthlyPayment.toFixed(2)} €
                      </TableCell>
                      <TableCell align="right">
                        {credit.loanAmount.toFixed(2)} €
                      </TableCell>
                      <TableCell align="right">
                        {(credit.loanAmount - credit.totalPayment).toFixed(2)} €
                      </TableCell>
                      <TableCell align="right">
                        {credit.totalDuration.years} Jahre und{" "}
                        {credit.totalDuration.months} Monate
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
};
