import { CreditOffer, Year } from "../store/finances";

const INTERHYP_ENDPOINT =
    "https://pure-sands-07853.herokuapp.com/https://www.interhyp.de/customer-generation/interest/marketOverview";

type FederalState =
    | "DE-BW"
    | "DE-BY"
    | "DE-BE"
    | "DE-BB"
    | "DE-HB"
    | "DE-HH"
    | "DE-HE"
    | "DE-NI"
    | "DE-MV"
    | "DE-NW"
    | "DE-RP"
    | "DE-SL"
    | "DE-SN"
    | "DE-ST"
    | "DE-SH"
    | "DE-TH";

type InterhypRequest = {
    caseDto: {
        estate: {
            zip: string;
            city: string;
            federalState: FederalState;
        };
        mainApplicant: {
            city: string;
            federalState: FederalState;
            netSalary: number;
        };
        capital: {
            equityCash: number;
        };
        venture: {
            reason: "KaufBest";
            priceBuilding: number;
            percentageBroker: number;
            percentageNotary: number;
            percentageTax: number;
            shownFunding: {
                equityCash: number;
                loans: [
                    {
                        amount: number;
                        maturity: number;
                        fullRepayment: boolean;
                        amortisation: number;
                    }
                ];
            };
            brokerCosts: number;
            notaryCosts: number;
            transferTax: number;
        };
        calledBy: "zinscheck18";
    };
    numberOfResults: number;
};

export type InterhypResult = {
    nominalInterest: number;
    effectiveInterest: number;
    monthlyPayment: number;
    totalLoanDurationMonths: number;
    fixedPeriodWanted: number;
    costsBroker: number;
    costsNotary: number;
    costsTax: number;
    loanAmount: number;
    paymentLeftAfterFixedPeriod: number;
    totalPayment: number;
    totalInterestPayment: number;
    totalDuration: { years: number; months: number };
    bankDetails: { bankName: string; bankAddress: string };
}[];

export class InterhypRequestBuilder {
    private request: InterhypRequest;
    constructor(
        priceBuilding: number,
        percentageBroker: number,
        percentageNotary: number,
        percentageTax: number
    ) {
        this.request = {
            caseDto: {
                estate: { zip: "04103", city: "Leipzig", federalState: "DE-SN" },
                mainApplicant: { city: "", federalState: "DE-SN", netSalary: 8000 },
                capital: { equityCash: 0 },
                venture: {
                    reason: "KaufBest",
                    priceBuilding,
                    percentageBroker,
                    percentageNotary,
                    percentageTax,
                    shownFunding: {
                        equityCash: 0,
                        loans: [
                            {
                                amount:
                                    priceBuilding +
                                    (percentageBroker / 100) * priceBuilding +
                                    (percentageNotary / 100) * priceBuilding +
                                    (percentageTax / 100) * priceBuilding,
                                maturity: 15,
                                fullRepayment: false,
                                amortisation: 2,
                            },
                        ],
                    },
                    brokerCosts: (priceBuilding * percentageBroker) / 100,
                    notaryCosts: (priceBuilding * percentageNotary) / 100,
                    transferTax: (priceBuilding * percentageTax) / 100,
                },
                calledBy: "zinscheck18",
            },
            numberOfResults: 1,
        };
    }

    equity(equity: number): InterhypRequestBuilder {
        this.request.caseDto.venture.shownFunding.equityCash = equity;
        this.request.caseDto.venture.shownFunding.loans[0].amount =
            this.request.caseDto.venture.shownFunding.loans[0].amount - equity;
        return this;
    }

    build(): InterhypRequest {
        return this.request;
    }
}

export async function loadCredits(
    years: Year[],
    percentageBroker: number,
    percentageTax: number,
    percentageNotary: number
): Promise<CreditOffer[][]> {
    return await Promise.all(
        years.map((year) => {
            const request = new InterhypRequestBuilder(
                year.propertyPrice,
                percentageBroker,
                percentageNotary,
                percentageTax
            );
            request.equity(year.equity);
            return getInterhypResults(request.build());
        })
    );
}

export async function loadHypotheticalCredits(
    years: Year[],
    percentageBroker: number,
    percentageTax: number,
    percentageNotary: number
): Promise<CreditOffer[][]> {
    return await Promise.all(
        years.map((year) => {
            const request = new InterhypRequestBuilder(
                year.hypotheticalPopertyPrice,
                percentageBroker,
                percentageNotary,
                percentageTax
            );
            request.equity(year.equity);
            return getInterhypResults(request.build());
        })
    );
}

export async function getInterhypResults(
    request: InterhypRequest
): Promise<CreditOffer[]> {
    return new Promise((resolve, reject) => {
        fetch(INTERHYP_ENDPOINT, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(request),
        })
            .then((data) => data.json())
            .then((data) => {
                const offers = mapResultToOffers(data);
                resolve(offers);
            })
            .catch((error) => reject(error));
    });
}

function mapResultToOffers(results: InterhypResult): CreditOffer[] {
    return results.map((re) => ({
        bank: re.bankDetails.bankName,
        nominal: re.nominalInterest,
        effective: re.effectiveInterest,
        paymentLeft: re.paymentLeftAfterFixedPeriod,
        leftAfter: re.fixedPeriodWanted,
        totalPayment: re.totalPayment,
        loanAmount: re.loanAmount,
        totalInterestPayment: re.totalInterestPayment,
        totalDuration: re.totalDuration,
        monthlyPayment: re.monthlyPayment,
    }));
}
