import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Preisindex } from '../services/destatis';
import { calculateYears } from '../services/years';

export type Year = {
  id: number;
  year: number;
  rent: number;
  sumRent: number,
  savingRate: number;
  equity: number;
  propertyPrice: number;
  hypotheticalPopertyPrice: number
};

export type CreditOffer = {
  nominal: number;
  effective: number;
  monthlyPayment: number;
  totalDuration: {
    years: number;
    months: number;
  };
  paymentLeft: number;
  leftAfter: number;
  totalPayment: number;
  loanAmount: number;
  totalInterestPayment: number;
  bank: string;
};

export type FinanceState = {
  equity: number,
  rent: number,
  monthlySavings: number,
  rentIncrease: number,
  savingIncrease: number,
  propertyPrice: number,
  years: Year[],
  credits: CreditOffer[],
  hypotheticalCredits: CreditOffer[],
  priceIncreaseRate: number,
  priceIncrease: Preisindex | null,
  reloadRequired: boolean
}

const initialState: FinanceState = {
  equity: 0,
  rent: 1560,
  monthlySavings: 2000,
  rentIncrease: 1.5,
  savingIncrease: 5,
  propertyPrice: 600000,
  years: [],
  credits: [],
  hypotheticalCredits: [],
  priceIncreaseRate: 0,
  priceIncrease: null,
  reloadRequired: false,
}

const financeSlice = createSlice({
  name: 'finances',
  initialState,
  reducers: {
    setEquity(state: FinanceState, action: PayloadAction<number>) {
      state.equity = action.payload
      state.reloadRequired = true
      state.years = calculateYears(state)
    },
    setRent(state: FinanceState, action: PayloadAction<number>) {
      state.rent = action.payload
      state.reloadRequired = true
      state.years = calculateYears(state)
    },
    setRentIncrease(state: FinanceState, action: PayloadAction<number>) {
      state.rentIncrease = action.payload
      state.reloadRequired = true
      state.years = calculateYears(state)
    },
    setSavingIncrease(state: FinanceState, action: PayloadAction<number>) {
      state.savingIncrease = action.payload
      state.reloadRequired = true
      state.years = calculateYears(state)
    },
    setMonthlySavings(state: FinanceState, action: PayloadAction<number>) {
      state.monthlySavings = action.payload
      state.reloadRequired = true
      state.years = calculateYears(state)
    },
    setPropertyPrice(state: FinanceState, action: PayloadAction<number>) {
      state.propertyPrice = action.payload
      state.reloadRequired = true
      state.years = calculateYears(state)
    },
    setPriceIncreaseRate(state: FinanceState, action: PayloadAction<number>) {
      state.priceIncreaseRate = action.payload
      state.reloadRequired = true
      state.years = calculateYears(state)
    },
    setPriceIncrease(state: FinanceState, action: PayloadAction<Preisindex>) {
      state.priceIncrease = action.payload
      state.reloadRequired = true
      state.years = calculateYears(state)
    },
    setCredits(state: FinanceState, action: PayloadAction<CreditOffer[]>) {
      state.reloadRequired = false
      state.credits = action.payload
    },
    setHypotheticalCredits(state: FinanceState, action: PayloadAction<CreditOffer[]>) {
      state.reloadRequired = false
      state.hypotheticalCredits = action.payload
    }

  }
})

export const { setEquity, setRent, setRentIncrease, setSavingIncrease, setMonthlySavings, setPropertyPrice, setPriceIncreaseRate, setCredits, setHypotheticalCredits, setPriceIncrease } = financeSlice.actions
export default financeSlice.reducer


