import { FinanceState, Year } from "../store/finances";

export function calculateYears(
  { equity: baseEquity, rent: baseRent, propertyPrice, monthlySavings: baseMonthlySavings, priceIncrease, rentIncrease, savingIncrease }: FinanceState
): Year[] {
  const currentMonth = new Date().getMonth() + 1;
  const rows = new Array(10);
  const years = rows.fill(0).map((_val, i) => {
    let equity = baseEquity
    let rent = baseRent
    let monthlySavings = baseMonthlySavings
    let sumRent = 0
    let hypotheticalPopertyPrice = propertyPrice
    for (let y = 0; y < i + 1; y++) {
      if (y > 0) {
        rent += rent * rentIncrease / 100
        monthlySavings += monthlySavings * savingIncrease / 100
        hypotheticalPopertyPrice = hypotheticalPopertyPrice + hypotheticalPopertyPrice * (priceIncrease?.increaseRate ?? 100) / 100
      }
      let factor = (y === 0 ? 12 - currentMonth : 12)
      equity += monthlySavings * factor;
      sumRent += rent * factor
    }
    return {
      id: i,
      year: new Date(
        new Date().getTime() + 365 * 24 * 60 * 60 * 1000 * i
      ).getFullYear(),
      rent,
      sumRent,
      savingRate: monthlySavings,
      equity: equity,
      propertyPrice,
      hypotheticalPopertyPrice,
    };
  });
  return years;
}
