import { Box, Typography } from "@mui/material";
import { FC } from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useAppSelector } from "../hooks/redux";
import { Year } from "../store/finances";

export const Results: FC = () => {
  const { years, credits, hypotheticalCredits } = useAppSelector(
    (state) => state.finances
  );

  const data = years.map((y: Year, i) => {
    const offer = credits[i];
    const hypo = hypotheticalCredits[i];
    return {
      year: y.year,
      equity: y.equity,
      rent: y.rent.toFixed(2),
      sumRent: y.sumRent,
      sumRentCredit: y.sumRent + offer?.loanAmount + y.equity,
      sumRentHypoCredit: y.sumRent + hypo?.loanAmount + y.equity,
      tax: offer?.effective,
      hypoTax: hypo?.effective,
      monthly: offer?.monthlyPayment,
      hypoMonthly: hypo?.monthlyPayment,
      creditVolume: offer?.loanAmount,
      hypoCreditVolume: hypo?.loanAmount,
      taxVolume:
        offer?.monthlyPayment * offer?.totalDuration.years * 12 +
        offer?.totalDuration.months -
        offer?.loanAmount,
      hypoTaxVolume:
        hypo?.monthlyPayment * hypo?.totalDuration.years * 12 +
        hypo?.totalDuration.months -
        hypo?.loanAmount,
    };
  });
  return (
    <div>
      <h3
        style={{
          color: "darkgreen",
          paddingBottom: "6px",
          borderBottom: "1px solid darkgreen",
        }}
      >
        Ergebnisse
      </h3>
      <Typography
        sx={{
          padding: "5px 0 15px",
          margin: "auto",
        }}
      >
        Nun geht es an die Auswertung. Zuerst stellen wir die Entwicklung des{" "}
        <i>effektiven Zinssatzes</i> und der <i>monatlichen Rate</i> in einer
        Darstellung dar. In Abhängigkeit davon wie hoch die monatlich zu
        stämmende Kreditrate sein kann, ist hier abzulesen, wann ein Kauf
        sinnvoll sein kann. Sollte die Kurve ein lokales Minimum aufweisen, wäre
        dies ein guter Indikator für einen optimalen Kaufzeitpunkt. Sollte die
        Kurve Bereiche vergleichsweise starken Abfalls aufweisen (wie im Fall
        von sehr geringem aktuellen Eigenkapital mit hoher monatlicher
        Sparrate), dann ist es vermutlich günstig bis an das Ende des Abfalls zu
        warten.
      </Typography>
      <Box
        sx={{
          width: "100%",
          margin: "6px 0",
        }}
      >
        <ResponsiveContainer width="100%" height={500}>
          <LineChart
            data={data}
            margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
          >
            <Line
              yAxisId="percentage"
              type="monotone"
              dataKey="hypoTax"
              stroke="#8A2BE2"
              name="Effektiver Zinssatz [hypothetisch]"
            />
            <Line
              yAxisId="monthly"
              type="monotone"
              dataKey="hypoMonthly"
              stroke="#D2691E"
              name="Monatliche Rate [hypothetisch]"
            />
            <Line
              yAxisId="percentage"
              type="monotone"
              dataKey="tax"
              stroke="#8FBC8F"
              name="Effektiver Zinssatz"
            />
            <Line
              yAxisId="monthly"
              type="monotone"
              dataKey="monthly"
              stroke="#556B2F"
              name="Monatliche Rate"
            />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis dataKey="year" />
            <YAxis yAxisId="percentage" orientation="right" unit="%" />
            <YAxis width={100} yAxisId="monthly" orientation="left" unit="€" />
            <Tooltip />
            <Legend />
          </LineChart>
        </ResponsiveContainer>
      </Box>
      <Typography
        sx={{
          padding: "5px 0 15px",
          margin: "auto",
        }}
      >
        Nun widmen wir uns den Summen. Es wird die{" "}
        <i>Eigenkapitalentwicklung</i> gegen das <i>Kredit-</i> und{" "}
        <i>Zinsvolumen</i> dargestellt. Das Zinsvolumen meint dabei, die Summe
        die rein für die Tilgung der Zinsen notwendig ist. Diese Darstellung
        dient dazu ein Gefühl für die absoluten Summen und die Zinsbelastungen
        zu bekommen.
      </Typography>
      <Box
        sx={{
          width: "100%",
          margin: "6px 0",
        }}
      >
        <ResponsiveContainer width="100%" height={500}>
          <LineChart
            data={data}
            margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
          >
            <Line
              yAxisId="equity"
              type="monotone"
              dataKey="equity"
              stroke="#6495ED"
              name="Eigenkapital"
            />
            <Line
              yAxisId="equity"
              type="monotone"
              dataKey="creditVolume"
              stroke="#556B2F"
              name="Kreditvolumen"
            />
            <Line
              yAxisId="equity"
              type="monotone"
              dataKey="taxVolume"
              stroke="#8FBC8F"
              name="Zinsvolumen"
            />
            <Line
              yAxisId="equity"
              type="monotone"
              dataKey="hypoCreditVolume"
              stroke="#D2691E"
              name="Kreditvolumen [hypothetisch]"
            />
            <Line
              yAxisId="equity"
              type="monotone"
              dataKey="hypoTaxVolume"
              stroke="#8A2BE2"
              name="Zinsvolumen [hypothetisch]"
            />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis dataKey="year" />
            <YAxis width={100} yAxisId="equity" orientation="left" unit="€" />
            <Tooltip />
            <Legend />
          </LineChart>
        </ResponsiveContainer>
      </Box>
      <Typography
        sx={{
          padding: "5px 0 15px",
          margin: "auto",
        }}
      >
        Wir wollen uns nun die Gesamtkosten über die Zeit ansehen. Dargestellt
        ist die{" "}
        <i>
          Summe aus Mietzahlung, Eigenkapital und des Kreditvolumens über die
          Zeit
        </i>
        . Es ist also die Gesamtsumme an Kosten bis zur Abzahlung der Immobilie,
        unter der Annahme, dass die Immobilie selbstgenutzt ist. Etwas anderes
        als ein Anstieg deutet auf einen besseren Kaufzeitpunkt als den
        momentanen Zeitpunkt hin. Ein Minimum wäre unter den Gesichtspunkten der
        optimale Moment.
      </Typography>
      <Box
        sx={{
          width: "100%",
          margin: "6px 0",
        }}
      >
        <ResponsiveContainer width="100%" height={500}>
          <LineChart
            data={data}
            margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
          >
            <Line
              yAxisId="equity"
              type="monotone"
              dataKey="sumRentCredit"
              stroke="#6495ED"
              name="Gesamtsumme"
            />
            <Line
              yAxisId="equity"
              type="monotone"
              dataKey="sumRentHypoCredit"
              stroke="#556B2F"
              name="Gesamtsumme [hypothetisch]"
            />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis dataKey="year" />
            <YAxis width={100} yAxisId="equity" orientation="left" unit="€" />
            <Tooltip />
            <Legend />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </div>
  );
};
