export type EigentumswohnungPreisindex = {
    Quartal: string;
    "Dünn besiedelte ländliche Kreise": string;
    "Kreisfreie Großstädte (ohne Metropolen)": string;
    "Ländliche Kreise mit Verdichtungsansätzen": string;
    "Städtische Kreise": string;
    "Top 7 Metropolen": string;
};

export type WohneigentumPreisindex = {
    [Year: number]: string,
    Einheit: string,
    "Gegenstand der Nachweisung": string
}

export type Preisindex = {
    years: {
        year: number,
        index: number
    }[]
    increaseRate: number,
    name: string
}


type Interval = "Quarterly" | "Yearly";

const PROXY_URL = "https://pure-sands-07853.herokuapp.com/"

/**
 * @param interval Interval
 * @returns Average increase since 2015 in %
 */
export async function averageEigentumswohnungPriceIncrease(
    interval: Interval
): Promise<number> {
    let result = await grabTableFromUrl<EigentumswohnungPreisindex>("https://www.destatis.de/DE/Themen/Wirtschaft/Preise/_Grafik/_Interaktiv/wohnimmobilien-eigentumswohnungen.html;jsessionid=4547A73C0F051CD8CCA035E9670FFF52.live711?nn=214072&cms_showChartData=1", ".chartData");
    if (interval === "Yearly") {
        result = result.filter((val, _i, original) => {
            return original.indexOf(val) % 4 === 1;
        });
    }
    const percentages = result.map((r) => parseFloat(r["Städtische Kreise"]));
    const growth =
        (percentages[percentages.length - 1] - percentages[0]) /
        percentages.length /
        (interval === "Yearly" ? 1 : 4);
    return growth;
}

export async function averageSelfUsedPropertyPriceIncrease(): Promise<Preisindex> {
    let result = await grabTableFromUrl<WohneigentumPreisindex>('https://www-genesis.destatis.de/genesis/online?sequenz=tabelleErgebnis&selectionname=61262-0003#abreadcrumb', '#ET', ['Preisindizes für Wohnimmobilien'])
    let wohneigentum = result.filter(r => r["Gegenstand der Nachweisung"] === 'Preisindex für selbst genutztes Wohneigentum')[0]
    let yearsNumbers = Object.keys(wohneigentum).filter(k => /\d+/.test(k)).map(y => parseInt(y))
    let years = yearsNumbers.map((y: number) => {
        if (!wohneigentum[y]) console.error(wohneigentum, y)
        const groups = wohneigentum[y]?.match(/[^0-9]*(\d+),(\d).*/)
        let index = 100
        if (groups) {
            index = parseFloat(`${groups[1]}.${groups[2]}`)
        }
        return { year: y, index }
    })
    let increaseRate = (years[years.length - 1].index - years[0].index) / years.length
    return {
        name: 'Preisindex für selbst genutztes Wohneigentum',
        years,
        increaseRate
    }
}

async function grabTableFromUrl<T>(url: string, tableSelector: string, columnBlacklist: string[] = []): Promise<T[]> {
    return new Promise((resolve, reject) => {
        fetch(
            `${PROXY_URL}${url}`
        )
            .then((data) => data.text())
            .then((html) => {
                const el = document.createElement("html");
                el.innerHTML = html;

                const table = el.querySelector(tableSelector);
                if (table) {
                    let result = mapTableToObject<T>(table, columnBlacklist);
                    resolve(result);
                } else {
                    reject("Nothing Found");
                }
            })
            .catch((err) => {
                reject(err);
            });
    });
}

function mapTableToObject<T>(table: Element, columnBlacklist: string[]): T[] {
    const result: T[] = [];
    const headers = Array.from(table.querySelectorAll("th")).filter(h => !columnBlacklist.some((val) => h.innerText.includes(val)));
    const rows = table.querySelectorAll("tbody tr");
    rows.forEach(function(current, index) {
        result[index] = {} as T;
        current
            .querySelectorAll("th,td")
            .forEach(function(cell, cellIndex: number) {
                // @ts-ignore
                result[index][headers[cellIndex].innerText] = cell.innerHTML;
            });
    });
    return result;
}
