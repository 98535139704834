import "./App.css";
import { Box, CircularProgress, Grid } from "@mui/material";
import { RentAndCapital } from "./tiles/rentandcapital";
import { CreditOffers } from "./tiles/credits";
import { useEffect } from "react";
import { Results } from "./tiles/results";
import { averageSelfUsedPropertyPriceIncrease } from "./services/destatis";
import { setPriceIncrease } from "./store/finances";
import { useAppDispatch, useAppSelector } from "./hooks/redux";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

function App() {
  const { priceIncrease } = useAppSelector((state) => state.finances);
  const dispatch = useAppDispatch();

  useEffect(() => {
    averageSelfUsedPropertyPriceIncrease().then((preisindex) => {
      dispatch(setPriceIncrease(preisindex));
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      sx={{
        minHeight: "100vh",
        maxWidth: "1000px",
        margin: "auto",
      }}
    >
      <Grid
        item
        sx={{
          color: "darkgreen",
          borderBottom: "1px solid darkgreen",
          textAlign: "center",
        }}
      >
        <h2 style={{ fontStyle: "italic" }}>
          Übersicht und Hypothesen zur Finanzplanung
        </h2>
      </Grid>
      <Grid
        className="tile"
        item
        sx={{
          padding: "12px",
          "@media (min-width: 720px)": {
            width: "75vw",
            padding: "6px",
          },
        }}
      >
        <p>
          Diese Seite soll dazu dienen besser einschätzen zu können wann der
          Schritt für den Erwerb von Wohneigentum sinnvoll sein kann. Dazu wird
          der Preisindex für selbst genutzes Wohneigentum des{" "}
          <a
            style={{
              textDecoration: "none",
              fontWeight: "bold",
              color: "darkgreen",
            }}
            href="https://www.destatis.de/DE/Home/_inhalt.html"
          >
            statistischen Bundesamtes
          </a>{" "}
          verwendet und der Zinsentwicklung bei steigendem Eigenkapital
          entgegensetzt.
        </p>
        <p>
          <b>Wichtig: </b> Wie sich die Immobilienpreise und die Zinsen
          tatsächlich in den kommenden Jahren entwickeln werden ist natürlich
          nicht bekannt. Hier wird unter der Annahme extrapoliert, dass es
          ähnlich weitergeht wie es die letzten Jahre war. Das ist spekulativ
          und wird sich von der Realtiät unterscheiden.
        </p>
        <p>
          Die Entwicklung des Preisindexes ist unten dargestellt. Daraus ergibt
          sich ein durchschnittlicher Ansteig von{" "}
          <b>{priceIncrease?.increaseRate.toFixed(2)}%</b>.
        </p>
        {priceIncrease ? (
          <Box sx={{}}>
            <ResponsiveContainer width="100%" height={500}>
              <LineChart
                data={priceIncrease?.years}
                margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
              >
                <Line
                  yAxisId="percentage"
                  type="monotone"
                  dataKey="index"
                  stroke="#82ca9d"
                  name={priceIncrease?.name}
                />
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <XAxis dataKey="year" />
                <YAxis
                  yAxisId="percentage"
                  unit="%"
                  domain={["dataMin-10", "dataMax+10"]}
                />
                <Tooltip />
                <Legend verticalAlign="top" />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        ) : (
          <Box sx={{ margin: "12px 0", textAlign: "center" }}>
            <CircularProgress color="success" />
          </Box>
        )}
      </Grid>
      <Grid className="tile" item>
        <RentAndCapital />
      </Grid>
      <Grid className="tile" item>
        <CreditOffers />
      </Grid>
      <Grid className="tile" item>
        <Results />
      </Grid>
    </Grid>
  );
}

export default App;
