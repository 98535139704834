import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Grid,
  TextField,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { FC, useState } from "react";
import { setEquity, setMonthlySavings, setRent, setRentIncrease, setSavingIncrease } from "../store/finances";
import { useAppDispatch, useAppSelector } from "../hooks/redux";

export const RentAndCapital: FC = () => {
  const [expanded, setExpanded] = useState(true)
  const { rent, rentIncrease, savingIncrease, monthlySavings, equity, years } = useAppSelector(state => state.finances)
  const dispatch = useAppDispatch()

  return (
    <div>
      <h3 style={{
        color: 'darkgreen',
        borderBottom: '1px solid darkgreen',
        paddingBottom: '6px'
      }}>Miete und Eigenkapital</h3>
      <p>
        Im ersten Schritt soll die Miet- und Eigenkapitalentwicklung aufgenommen werden.
      </p>
      <Grid container spacing={4} justifyContent="flex-start">
        <Grid item md={2} xs={6}>
          <TextField
            sx={{
              width: "100%",
            }}
            variant="outlined"
            label="Kaltmiete"
            name="baseRent"
            margin="normal"
            type="number"
            value={rent}
            onChange={(e) => dispatch(setRent(parseInt(e.target.value)))}
          />
        </Grid>
        <Grid item md={2} xs={6}>
          <TextField
            sx={{
              width: "100%",
            }}
            variant="outlined"
            label="Sparrate"
            margin="normal"
            name="baseSavingRate"
            type="number"
            value={monthlySavings}
            onChange={(e) => dispatch(setMonthlySavings(parseInt(e.target.value)))}
          />
        </Grid>
        <Grid item md={2} xs={6}>
          <TextField
            sx={{
              width: "100%",
            }}
            variant="outlined"
            label="Eigenkapital"
            margin="normal"
            name="equity"
            type="number"
            value={equity}
            onChange={(e) => dispatch(setEquity(parseInt(e.target.value)))}
          />
        </Grid>
        <Grid item md={2} xs={6}>
          <TextField
            sx={{
              width: "100%",
            }}
            variant="outlined"
            label="Jährliche Mieterhöhung"
            margin="normal"
            name="rentIncrease"
            type="number"
            value={rentIncrease}
            onChange={(e) => dispatch(setRentIncrease(parseInt(e.target.value)))}
          />
        </Grid>
        <Grid item md={2} xs={6}>
          <TextField
            sx={{
              width: "100%",
            }}
            variant="outlined"
            label="Jährliche Sparerhöhung"
            margin="normal"
            name="savingIncrease"
            type="number"
            value={savingIncrease}
            onChange={(e) => dispatch(setSavingIncrease(parseInt(e.target.value)))}
          />
        </Grid>
      </Grid>
      <Accordion expanded={expanded} onChange={(_e, ex) => setExpanded(ex)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '70%', flexShrink: 0 }}>
            Entwicklung der Aktuellen Miete und des gesparten Kapitals
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Jahr</TableCell>
                  <TableCell align="right">Monatliche Miete</TableCell>
                  <TableCell align="right">Monatliche Sparrate</TableCell>
                  <TableCell align="right">Angespartes Kapital</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {years?.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.year}
                    </TableCell>
                    <TableCell align="right">{row.rent.toFixed(2)} €</TableCell>
                    <TableCell align="right">
                      {row.savingRate.toFixed(2)} €
                    </TableCell>
                    <TableCell align="right">{row.equity.toFixed(2)} €</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </div >
  );
};
